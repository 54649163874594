import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SingleNewsLayout from '../components/singleNewsLayout'
import Seo from '../components/Seo'
//IF user don't provide featured image
import defaultImage from '../images/single-cpt-placeholder.png'

export const SingleNewsTemplate = ({ content }) => {
  return (
    <div
      className="cpt-content"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

SingleNewsTemplate.propTypes = {
  content: PropTypes.string,
}

const SingleNews = ({ data }) => {
  const {
    wordpressWpNews: { title, content, date, featured_media, link, slug },
  } = data
  const shareContent = content.slice(4, 60)

  return (
    <SingleNewsLayout
      title={title}
      date={date}
      headerImage={!featured_media ? defaultImage : featured_media.source_url}
      articleUrl={link}
      slug={slug}>
      <Seo
        title={title}
        description={shareContent}
        imageSrc={featured_media ? featured_media.source_url : null}
      />
      <SingleNewsTemplate content={content} />
    </SingleNewsLayout>
  )
}

SingleNews.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SingleNews

export const singleNewsQuery = graphql`
  query newsById($id: String!) {
    wordpressWpNews(id: { eq: $id }) {
      title
      content
      slug
      link
      featured_media {
        source_url
      }
    }
  }
`
