import React from 'react'
import PropTypes from 'prop-types'

import './header-image.scss'

//Header image component
function HeaderImage({ bgImageUrl, className }) {
  return (
    <div
      className={`header-image ${className}`}
      style={{ backgroundImage: `url('${bgImageUrl}')` }}></div>
  )
}

HeaderImage.propTypes = {
  /**
   * Background image url
   */
  bgImageUrl: PropTypes.string.isRequired,
  /**
   * ClassName for extra class if we need
   */
  className: PropTypes.string,
}
export default HeaderImage
