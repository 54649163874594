import React from 'react'
import PropTypes from 'prop-types'

//icons
import facebookIcon from './facebook.svg'
import twitterIcon from './twitter.svg'

import { getMonthName } from '../../utils'

import './socials-share-title.scss'

//Title with facebook & twitter share options
function SocialsShareTitle({ title, date, articleUrl, cptName, slug }) {
  const finalDate = getMonthName(date)
  return (
    <section className="social-share-title">
      <div className="social-share-title__wrapper">
        <h1>{title}</h1>
        <p className="date date--single">{finalDate} </p>
      </div>
      <div className="social-share-title__socials">
        <p>DEILA: </p>
        <div clasName="social-share-title__socials-wrapp">
          <div
            data-href={articleUrl}
            className="fb-share-button fb-custom"
            data-layout="button"
            data-size="small">
            <a
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=http://bvv.is/${cptName}/${slug}`}
              className="fb-xfbml-parse-ignore"></a>
          </div>
          <img src={facebookIcon} alt="facebook icon" />
          <a
            className="twitter-share-button"
            href={`https://twitter.com/intent/tweet?text=${title}`}>
            <img src={twitterIcon} alt="twitter icon" />
          </a>
        </div>
      </div>
    </section>
  )
}

SocialsShareTitle.propTypes = {
  //Title of the component
  title: PropTypes.string.isRequired,
  //Publishing date
  date: PropTypes.string,
  // Post Url
  articleUrl: PropTypes.string,
}

export default SocialsShareTitle
