/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 * This is for single news CPT
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import Header from './Header'
import HeaderImage from '../bvv-styleguide/components/header-image/HeaderImage'
import SocialsShareTitle from '../bvv-styleguide/components/socials-share-title/SocialsShareTitle'
import Footer from './Footer'

import './global.scss'

const SingleNewsLayout = ({
  headerImage,
  title,
  date,
  children,
  articleUrl,
  slug,
}) => {
  //Form state
  return (
    <>
      <Helmet title={`${title}| BVV`} />
      <Header />
      <HeaderImage bgImageUrl={headerImage} />
      <div className="post-content">
        <SocialsShareTitle
          title={title}
          date={date}
          articleUrl={articleUrl}
          slug={slug}
          cptName="frettir"
        />
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

SingleNewsLayout.propTypes = {
  //Featured image
  headerImage: PropTypes.string,
  //Post title
  title: PropTypes.string.isRequired,
  //Publish date
  date: PropTypes.string,
  //Components from admin
  children: PropTypes.node.isRequired,
  // Post Url
  articleUrl: PropTypes.string,
  //Slug
  slug: PropTypes.string,
}

export default SingleNewsLayout
